import { Footer as DDSFooter, FooterLeft, FooterLogo, Grid, GridChild } from '@norges-domstoler/dds-components';
import { norwegian_language_tag_short } from '../../../Models/Constants/LanguageTypes';
import LayoutSettings from 'app/Models/Content/LayoutSettingsData';
import FooterSocialMediaSection from './FooterSocialMediaSection';
import FooterSection from './FooterSection';
import { LinkProperty } from '@episerver/spa-core/dist/Property';

export const FooterDomstolno = ({ settings }: { settings: LayoutSettings }) => {
    if (!settings) {
        return null;
    }

    const language = settings?.language?.name ?? norwegian_language_tag_short;

    const renderFooterSection = (header: string, links: (LinkProperty | undefined)[]) => (
        <FooterSection header={header} links={links.filter(Boolean) as LinkProperty[]} />
    );

    return (
        <DDSFooter>
            <Grid
                as="div"
                className="container page-container footer-max-width"
                rowGap={{ sm: 'var(--dds-spacing-x2-5)', xs: 'var(--dds-spacing-x2-5)' }}
            >
                <GridChild columnsOccupied={{ xl: '1/4', lg: '1/4', md: '1/4', sm: '1/-1', xs: '1/-1' }}>
                    <FooterLeft>
                        <FooterLogo src={`/StaticContent/logo/hovedlogo_negativ_${language}.svg`} hideBreakpoint="xs" />
                        <FooterSocialMediaSection socialMediaBlock={settings.socialMediaBlock} />
                    </FooterLeft>
                </GridChild>

                {settings?.ourServicesBlock && (
                    <GridChild columnsOccupied={{ xl: '4/7', lg: '4/7', md: '4/7', sm: '1/-1', xs: '1/-1' }}>
                        {renderFooterSection(settings.ourServicesBlock.header.value, [
                            settings.ourServicesBlock.aktorPortalenUrl?.value,
                            settings.ourServicesBlock.pressetjenesterUrl?.value,
                            settings.ourServicesBlock.whenGoesTrialUrl?.value,
                        ])}
                    </GridChild>
                )}

                {settings?.contactBlock && (
                    <GridChild columnsOccupied={{ xl: '7/10', lg: '7/10', md: '7/10', sm: '1/-1', xs: '1/-1' }}>
                        {renderFooterSection(settings.contactBlock.header.value, [
                            settings.contactBlock.contactUsUrl?.value,
                            settings.contactBlock.findCourtUrl?.value,
                        ])}
                    </GridChild>
                )}

                {settings?.aboutTheWebsiteBlock && (
                    <GridChild columnsOccupied={{ xl: '10/13', lg: '10/13', md: '10/13', sm: '1/-1', xs: '1/-1' }}>
                        {renderFooterSection(settings.aboutTheWebsiteBlock.header.value, [
                            settings.aboutTheWebsiteBlock.availabilityUrl?.value,
                            settings.aboutTheWebsiteBlock.privacyUrl?.value,
                        ])}
                    </GridChild>
                )}
            </Grid>
        </DDSFooter>
    );
};
export default FooterDomstolno;
