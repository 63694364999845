import AboutTheWebsiteBlockData from 'app/Models/Content/AboutTheWebsiteBlockData';
import { Footer, getLocalizationText } from '../../../utils/getLocalizationText';
import './FooterTilsynsutvalget.scss';
import { ContentDelivery } from '@episerver/spa-core';
import { Link } from '@norges-domstoler/dds-components';
import { getLinkTitleFromLinkProperty, getLinkUrlFromLinkProperty } from '../../../Components/Shared/Utils';
import HyphenateTitleElement from '../../../Components/Shared/renderTitle';
import { minWordLengthFooterText } from '../../../Models/Constants/SiteSettings';

interface FooterLinkProps {
    url: ContentDelivery.LinkProperty;
}

const FooterLink = ({ url }: FooterLinkProps) => {
    if (!url?.href) return null;

    return (
        <div className="col-12 mt-3">
            <Link className="dark-focus-internal-link footer-url" href={getLinkUrlFromLinkProperty(url)} withMargins>
                <p>
                    <HyphenateTitleElement
                        minWordLength={minWordLengthFooterText}
                        title={getLinkTitleFromLinkProperty(url)}
                    />
                </p>
            </Link>
        </div>
    );
};

interface FooterProps {
    aboutWebsite: AboutTheWebsiteBlockData;
    language: string;
}

export const FooterTilsynsutvalget = ({ aboutWebsite, language }: FooterProps) => {
    const ariaLabel = getLocalizationText(language, [Footer, 'Label']);

    return (
        <footer className="footer-max-width-section">
            <nav id="footer-nav" className="row footer-max-width container" aria-label={ariaLabel}>
                <div className="footer-links-container">
                    <div className="footer-links">
                        <h2 className="visually-hidden">{ariaLabel}</h2>
                        <FooterLink url={aboutWebsite?.availabilityUrl?.value} />
                        <FooterLink url={aboutWebsite?.privacyUrl?.value} />
                    </div>
                </div>
            </nav>
        </footer>
    );
};

export default FooterTilsynsutvalget;
