import LayoutSettings from 'app/Models/Content/LayoutSettingsData';
import { SiteType } from '../../../Models/Enums/SiteType';
import FooterDomstolno from './FooterDomstolno';
import FooterTU from './FooterTilsynsutvalget';

export const Footer = ({ settings }: { settings: LayoutSettings }) => {
    const siteType = settings?.siteType?.value;
    const language = settings?.language?.name;

    return (
        <>
            {siteType === SiteType.Tilsynsutvalget ? (
                <FooterTU aboutWebsite={settings.aboutTheWebsiteBlock} language={language} />
            ) : (
                <FooterDomstolno settings={settings} />
            )}
        </>
    );
};

export default Footer;
