import { getLinkTitleFromLinkProperty, getLinkUrlFromLinkProperty } from '../../Shared/Utils';
import { FooterList, FooterListGroup, FooterListHeader } from '@norges-domstoler/dds-components';
import { ContentDelivery } from '@episerver/spa-core';
import Link from '../../Shared/Link/link';
import HyphenateTitleElement from '../../../Components/Shared/renderTitle';
import { minWordLengthFooterText } from '../../../Models/Constants/SiteSettings';

const areAllLinksNull = (links: ContentDelivery.LinkProperty[]) => links?.every((link) => !link?.href);

export type FooterSectionProps = {
    header: string;
    links: ContentDelivery.LinkProperty[];
};

export const FooterSection = ({ header, links }: FooterSectionProps) => {
    if (areAllLinksNull(links)) {
        return null;
    }

    return (
        <FooterListGroup>
            <FooterListHeader>{header}</FooterListHeader>
            <FooterList>
                {links.map((link, index) => {
                    const key = link?.contentLink?.guidValue || link?.text || link?.title || index;

                    return (
                        <li key={`footer-link-${key}-${header}`}>
                            <Link href={getLinkUrlFromLinkProperty(link)}>
                                <HyphenateTitleElement
                                    minWordLength={minWordLengthFooterText}
                                    title={getLinkTitleFromLinkProperty(link)}
                                />
                            </Link>
                        </li>
                    );
                })}
            </FooterList>
        </FooterListGroup>
    );
};

export default FooterSection;
