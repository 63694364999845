import { SearchIcon, Button, Search, Typography } from '@norges-domstoler/dds-components';
import { getLocalizationText, General } from '../../../../utils/getLocalizationText';
import { useEpiserver } from '@episerver/spa-core';
import { useNavigate } from 'react-router';
import store from '../../../../store';

type SearchBoxHeaderProps = {
    isMobile: boolean;
    setIsOpenSearch: any;
    isOpenSearch: boolean;
    searchPath: string;
    language: string;
    path: string;
    searchFieldRef: React.MutableRefObject<undefined>;
};

export const SearchBoxHeader = (props: SearchBoxHeaderProps) => {
    const navigate = useNavigate();
    const ctx = useEpiserver();

    const handleButtonPress = (e: React.MouseEvent<HTMLButtonElement>) => {
        const searchUrl = getSearchUrl();
        if (searchUrl) {
            props.setIsOpenSearch(false);
            if (props.isMobile) {
                store.dispatch({ type: 'isMenuOpen', payload: false });
            }
            redirectToSearchPage(searchUrl);
        } else {
            e.preventDefault();
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement> & React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key == 'Enter') {
            const searchUrl = getSearchUrl();
            if (searchUrl) {
                props.setIsOpenSearch(false);
                if (props.isMobile) {
                    store.dispatch({ type: 'isMenuOpen', payload: false });
                }
                redirectToSearchPage(searchUrl);
            }
        }
    };

    function redirectToSearchPage(newSearchUrl: string) {
        navigate(ctx.getSpaRoute(newSearchUrl));
        const currentPath = props.path.split('?');
        const searchPage = ctx.getSpaRoute(newSearchUrl).split('?');
        if (currentPath.length > 0 && searchPage.length > 0 && currentPath[0] == searchPage[0]) {
            window.location.reload();
        }
    }

    const getSearchUrl = () => {
        if (
            props.searchFieldRef &&
            props.searchFieldRef.current &&
            props.searchFieldRef.current['value'] &&
            props.searchPath != null &&
            props.searchPath != '#'
        ) {
            let searchUrl = new URL(props.searchPath).pathname;
            searchUrl += '?search=' + props.searchFieldRef.current['value'];
            return searchUrl;
        }
        return null;
    };

    const searchLabel = getLocalizationText(props.language, [General, 'Search']);

    return (
        <>
            {props.isMobile ? (
                <form role="search" className="row col-12 col-lg-6" aria-label={searchLabel}>
                    <Typography
                        className="startpage-searchbar-header"
                        typographyType="headingSans03"
                        as="label"
                        // @ts-expect-error: "for" finnes ikke i htmlProps-definisjonen
                        htmlProps={{ htmlFor: 'mobile-header-search-box' }}
                    >
                        {getLocalizationText(props.language, [General, 'SearchInputLabel'])}
                    </Typography>
                    <div className="search-input-and-button">
                        <Search
                            id="mobile-header-search-box"
                            onKeyPress={handleKeyPress}
                            ref={props.searchFieldRef}
                            className="search-input-field"
                            autoFocus
                            buttonProps={{
                                onClick: handleButtonPress,
                                'aria-label': searchLabel,
                                label: searchLabel,
                            }}
                        />
                    </div>
                </form>
            ) : (
                <form
                    role="search"
                    className="row col-12"
                    onSubmit={(e) => e.preventDefault()}
                    onFocus={() => props.setIsOpenSearch(true)}
                >
                    <Typography
                        className="startpage-searchbar-header"
                        typographyType="headingSans03"
                        as="label"
                        // @ts-expect-error: "for" finnes ikke i htmlProps-definisjonen
                        htmlProps={{ htmlFor: 'header-search-box' }}
                    >
                        {getLocalizationText(props.language, ['Header', 'SearchQuestion'])}
                    </Typography>
                    <div className="col-9 search-input-and-button">
                        <Search
                            id="header-search-box"
                            tabIndex={props.isOpenSearch ? 0 : -1}
                            type="search"
                            onKeyPress={handleKeyPress}
                            ref={props.searchFieldRef}
                            className="search-input-field"
                            onFocus={() => props.setIsOpenSearch(true)}
                            buttonProps={{
                                label: searchLabel,
                                'aria-label': searchLabel,
                                onClick: handleButtonPress,
                                tabIndex: props.isOpenSearch ? 0 : -1,
                                onFocus: () => props.setIsOpenSearch(true),
                            }}
                        />
                    </div>
                </form>
            )}
        </>
    );
};

export default SearchBoxHeader;
