import { ContentDelivery } from '@episerver/spa-core';
import Link from '../../../../Components/Shared/Link/link';

type Link = {
    href: string;
    text: string;
};

type MenuItemLinkProps = {
    links: ContentDelivery.LinkListProperty;
    handleOnClick: () => void;
};

export const MenuItem = ({ links, handleOnClick }: MenuItemLinkProps) => {
    if (!links || !links.value || links.value.length < 1) return null;

    const linkList: Link[] = links.value.map((link: ContentDelivery.LinkProperty) => ({
        href: link.href,
        text: link.text || link.title,
    }));

    if (linkList.length === 1) {
        const primaryLink = linkList[0];
        return (
            <Link className="main-link" onClick={() => handleOnClick()} href={primaryLink.href}>
                {primaryLink.text}
            </Link>
        );
    } else {
        return (
            <ul className="tu-link-section">
                {linkList.map((link, index) => (
                    <li key={index}>
                        <Link
                            className={index === 0 ? 'main-link' : 'secondary-link'}
                            onClick={() => handleOnClick()}
                            href={link.href}
                        >
                            {link.text}
                        </Link>
                    </li>
                ))}
            </ul>
        );
    }
};

export default MenuItem;
