import { Typography } from '@norges-domstoler/dds-components';
import { ContentDelivery } from '@episerver/spa-core';
import { IsRecycleBinLink } from '../../../Shared/Utils';
import { HeaderProps } from '../Header/Header';
import LinkListLink from './LinkListLink';

type LinksListBlockProps = HeaderProps & {
    listProp: ContentDelivery.LinkListProperty;
    isMobile: boolean;
    isMenuOpen?: (isOpen: boolean) => void;
    isMenuOpenMobile?: (isOpen: boolean) => void;
    className?: string;
    header?: string;
};

export const LinksListBlock = (props: LinksListBlockProps) => {
    const listOfLinks = props.listProp?.value;
    if (!listOfLinks || listOfLinks.length < 1) {
        return null;
    }
    const links: JSX.Element[] = listOfLinks.map((linkListProp, index) => {
        if (!IsRecycleBinLink(linkListProp)) {
            return (
                <LinkListLink
                    key={`${index}${linkListProp.contentLink?.id}`}
                    isMobile={props.isMobile}
                    isMenuOpen={props.isMenuOpen}
                    isMenuOpenMobile={props.isMenuOpenMobile}
                    link={linkListProp}
                />
            );
        }
    });

    if (links.length < 1) {
        return null;
    }

    // different headings font size on mobile (ha en topp klasse (mobile) og sett ting basert på det)
    return (
        <div className={props.className}>
            <Typography typographyType="headingSans02" as="h2" className="me-5 mb-3 header-submenu-heading">
                {props.header}
            </Typography>
            {props.isMobile ? (
                <nav>
                    {links}
                    <div className="grid-horizontal-mobile-in-nav"></div>
                </nav>
            ) : (
                <ul className="ps-0 me-5" role="menu" aria-label={props.header}>
                    {links}
                </ul>
            )}
        </div>
    );
};

export default LinksListBlock;
