import { useEffect, useState, useRef } from 'react';

//Import Episerver Libs
import { useEpiserver, State } from '@episerver/spa-core';

//Import App
import {
    Typography,
    Icon,
    SearchIcon,
    MenuIcon,
    CloseIcon,
    ArrowLeftIcon,
    LanguageIcon,
    ArrowRightIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    Button,
} from '@norges-domstoler/dds-components';
import { getLocalizationText, General, Header } from '../../../../utils/getLocalizationText';
import { samegiella_language_tag_short } from '../../../../Models/Constants/LanguageTypes';
import LayoutSettings from '../../../../Models/Content/LayoutSettingsData';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';
import { LinksListBlock } from '../MenuItem/LinkListBlock';
import { SearchBoxHeader } from './SearchBoxHeader';
import { connect } from 'react-redux';
import store from '../../../../store';
import './Header.scss';
import Link from '../../../Shared/Link/link';

export type MobileProps = Partial<State.CmsState> & {
    dispatch?: (action: State.CmsStateAction) => void;
    setIsOpenLanguageSelector: any;
    isMenuOpen?: any;
    path?: string;
    searchPath?: string;
    language: string;
    settings: LayoutSettings;
    screenwidth: number;
    languageNames: { [id: string]: string };
    useLanguageSelector: boolean;
};

export const DefaultHeaderMobile = (props: MobileProps) => {
    const mobile_max_width = 480;

    const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false);
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
    const [subMenuType, setSubMenuType] = useState<string[]>([]);
    const [subMenuTypeSelected, setSubMenuTypeSelected] = useState<string>('');
    const [isOpenLanguage, setIsOpenLanguage] = useState<boolean>(false);

    const searchFieldRef = useRef();

    const anchorClickHandler = (menu: string) => {
        subMenuTypeSelected === menu ? setSubMenuTypeSelected('') : setSubMenuTypeSelected(menu);
        subMenuType.includes(menu)
            ? setSubMenuType(subMenuType.filter((x) => x !== menu))
            : setSubMenuType([...subMenuType, menu]);
    };

    const handleSubMenuLinkClick = (hasClickedOnLink: boolean) => {
        setIsOpenMenu(!hasClickedOnLink);
        store.dispatch({ type: 'isMenuOpen', payload: !hasClickedOnLink });
    };

    const toggleSearchMenuMobile = () => {
        props.isMenuOpen(!isOpenSearch);
        const prevState = isOpenSearch;
        setIsOpenSearch(!prevState);
        store.dispatch({ type: 'isMenuOpen', payload: !prevState });
        setIsOpenLanguage(false);
    };

    const toggleMenuMobile = () => {
        const prevState = isOpenMenu;
        setIsOpenMenu(!prevState);
        store.dispatch({ type: 'isMenuOpen', payload: !prevState });
        setIsOpenLanguage(false);
        setIsOpenSearch(false);
    };

    const toggleLanguageMenu = () => {
        setIsOpenLanguage((prevState) => !prevState);
    };

    useEffect(() => {
        if (isOpenMenu) {
            setSubMenuType([]);
            setSubMenuTypeSelected('');
        }
    }, [isOpenMenu]);

    return (
        <div className="header-mobile">
            <div
                className={
                    'header-top-mobile ' + (props.screenwidth > mobile_max_width ? 'two-fractions' : 'mobile-section')
                }
            >
                <div className="image-mobile">
                    <div className="col-12 col-lg header-image">
                        <Link
                            className="d-block"
                            onClick={() => {
                                setIsOpenMenu(false);
                                setIsOpenSearch(false);
                                store.dispatch({ type: 'isMenuOpen', payload: false });
                            }}
                            href="/"
                        >
                            <img
                                alt={getLocalizationText(props.language, [Header, 'Logo'])}
                                width="91"
                                height="48"
                                className={
                                    'mobile-logo ' + (props.language == samegiella_language_tag_short ? 'samisk' : '')
                                }
                                src={'/StaticContent/logo/horisontallogo_mobile_' + props.language + '.svg'}
                            />
                        </Link>
                    </div>
                </div>
                {props.screenwidth <= mobile_max_width && (
                    <div className="mobile-section-menu-search">
                        <Button
                            purpose="tertiary"
                            className={`header-buttons-language-and-search icon-background ${isOpenMenu ? 'd-none' : ''}`}
                            aria-expanded={isOpenSearch}
                            aria-controls="header-mobile-search-box-wrapper"
                            onClick={() => toggleSearchMenuMobile()}
                            icon={isOpenSearch ? CloseIcon : SearchIcon}
                            iconPosition="right"
                        >
                            {isOpenSearch
                                ? getLocalizationText(props.language, [General, 'Close'])
                                : getLocalizationText(props.language, [General, 'Search'])}
                        </Button>
                        <Button
                            purpose="tertiary"
                            className="header-buttons-language-and-search"
                            aria-label={getLocalizationText(props.language, [Header, 'MainMenu'])}
                            aria-expanded={isOpenMenu}
                            aria-controls="mobile-header-menu"
                            onClick={() => toggleMenuMobile()}
                            icon={isOpenMenu ? CloseIcon : MenuIcon}
                            iconPosition="right"
                        >
                            {isOpenMenu
                                ? getLocalizationText(props.language, [General, 'Close'])
                                : getLocalizationText(props.language, [Header, 'MainMenu'])}
                        </Button>
                    </div>
                )}
                {props.screenwidth > mobile_max_width && (
                    <div className="mobile-search-and-menu">
                        <Button
                            purpose="tertiary"
                            className={`header-buttons-language-and-search icon-background ${isOpenMenu ? 'd-none' : ''}`}
                            aria-label={getLocalizationText(props.language, [General, 'Search'])}
                            onClick={() => toggleSearchMenuMobile()}
                            icon={isOpenSearch ? CloseIcon : SearchIcon}
                            iconPosition="right"
                        >
                            {isOpenSearch
                                ? getLocalizationText(props.language, [General, 'Close'])
                                : getLocalizationText(props.language, [General, 'Search'])}
                        </Button>
                        <Button
                            purpose="tertiary"
                            className="header-buttons-language-and-search"
                            aria-label={getLocalizationText(props.language, [Header, 'MainMenu'])}
                            onClick={() => toggleMenuMobile()}
                            icon={isOpenMenu ? CloseIcon : MenuIcon}
                            iconPosition="right"
                        >
                            {isOpenMenu
                                ? getLocalizationText(props.language, [General, 'Close'])
                                : getLocalizationText(props.language, [Header, 'MainMenu'])}
                        </Button>
                    </div>
                )}
            </div>

            {/* dropdown from top menu mobile */}
            {(isOpenMenu || isOpenSearch) && (
                <div
                    className={
                        'dropdown-menu-mobile' + (isOpenLanguage || isOpenSearch ? ' show-languages ' : ' show-links ')
                    }
                >
                    {/* Search dropdown mobile */}
                    {isOpenSearch && (
                        <div
                            id="header-mobile-search-box-wrapper"
                            className={
                                'search-box-header-row dropdown-menu search-dropdown-mobile' +
                                (isOpenLanguage || isOpenSearch ? ' show-languages ' : ' show-links ')
                            }
                        >
                            <div className="py-5">
                                <div className="container page-container py-5">
                                    <div className="row">
                                        <SearchBoxHeader
                                            searchFieldRef={searchFieldRef}
                                            isOpenSearch={isOpenSearch}
                                            isMobile={true}
                                            setIsOpenSearch={(isOpenSearch: boolean) => setIsOpenSearch(isOpenSearch)}
                                            searchPath={props.searchPath}
                                            language={props.language}
                                            path={props.path}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Links and language dropdown mobile */}
                    {isOpenMenu && (
                        <nav
                            id="mobile-header-menu"
                            aria-label={getLocalizationText(props.language, [Header, 'MainMenu'])}
                            className={
                                'links-language-dropdown-mobile dropdown-menu' +
                                (isOpenLanguage || isOpenSearch ? ' show-languages ' : ' show-links ')
                            }
                        >
                            {isOpenLanguage && (
                                <div className="language-section-mobile">
                                    <div className="language-section-container left">
                                        <Button
                                            className="language-section-open"
                                            onClick={() => toggleLanguageMenu()}
                                            icon={ArrowLeftIcon}
                                            iconPosition="left"
                                            size="small"
                                            purpose="tertiary"
                                        >
                                            <Typography typographyType="bodySans02" underline>
                                                {getLocalizationText(props.language, [General, 'Back'])}
                                            </Typography>
                                        </Button>
                                    </div>

                                    <LanguageSelector
                                        useEnglish={props?.settings?.useEnglish?.value}
                                        useSamisk={props?.settings?.useSamisk?.value}
                                        layoutSettingsLanguage={props.settings?.language?.name}
                                        isMobile={true}
                                        useLanguageSelector={props.useLanguageSelector}
                                        isOpenLanguageSelector={isOpenLanguage}
                                        setIsOpenLanguageSelector={(isOpenLanguageSelector: boolean) => {
                                            setIsOpenMenu(false);
                                            setIsOpenLanguage(false);
                                            setIsOpenSearch(false);
                                            store.dispatch({ type: 'isMenuOpen', payload: isOpenLanguageSelector });
                                        }}
                                        isMenuOpen={(isMenuOpen: boolean) => props.isMenuOpen(isMenuOpen)}
                                        languageNames={props.languageNames}
                                        path={props.path}
                                    />
                                </div>
                            )}

                            {/* Link menu */}
                            {!isOpenLanguage && (
                                <>
                                    {props.useLanguageSelector && (
                                        <div className="language-section-container right">
                                            <Button
                                                purpose="tertiary"
                                                className="language-section"
                                                onClick={() => toggleLanguageMenu()}
                                                iconPosition="right"
                                                icon={LanguageIcon}
                                            >
                                                {getLocalizationText(props.language, [Header, 'Language'])}
                                            </Button>
                                        </div>
                                    )}

                                    <div
                                        className={
                                            'grid-horizontal-mobile' + (props.useLanguageSelector ? '' : ' mt-5')
                                        }
                                    ></div>
                                    {props?.settings?.findCourtFromHeader?.value ? (
                                        <Link
                                            className="main-menu-item-mobile"
                                            href={props.settings.findCourtFromHeader?.value?.href}
                                            onClick={() => {
                                                anchorClickHandler('');
                                                setIsOpenMenu(false);
                                                store.dispatch({ type: 'isMenuOpen', payload: false });
                                            }}
                                        >
                                            <Typography typographyType="bodySans02">
                                                {props.settings.findCourtFromHeader?.value?.text}
                                            </Typography>
                                            <Icon color="rgba(53, 71, 84, 1)" icon={ArrowRightIcon} iconSize="small" />
                                        </Link>
                                    ) : (
                                        <div></div>
                                    )}

                                    {(props.settings?.beforeMeetingTheCourtsLinks?.value?.length > 0 ||
                                        props.settings?.summonedForTrialLinks?.value?.length > 0 ||
                                        props.settings?.publicAndInsightsLinks?.value?.length > 0) && (
                                        <>
                                            <div className="grid-horizontal-mobile"></div>
                                            <Link
                                                className={`main-menu-item-mobile ${
                                                    subMenuTypeSelected === 'inCourt' ? 'selected' : ''
                                                }`}
                                                href="#incourt"
                                                onClick={() => anchorClickHandler('inCourt')}
                                            >
                                                <Typography typographyType="bodySans02" as="div" id="inCourt">
                                                    {props.settings?.inCourtHeading?.value}
                                                </Typography>
                                                <Icon
                                                    color="rgba(53, 71, 84, 1)"
                                                    icon={
                                                        subMenuType.includes('inCourt')
                                                            ? ChevronUpIcon
                                                            : ChevronDownIcon
                                                    }
                                                    iconSize="small"
                                                />
                                            </Link>
                                            {subMenuType.includes('inCourt') && (
                                                <div className={`submenus-mobile pt-5`}>
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.beforeMeetingTheCourtsHeading.value}
                                                        listProp={props.settings.beforeMeetingTheCourtsLinks}
                                                        className="mb-5"
                                                    />
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.summonedForTrialHeading.value}
                                                        listProp={props.settings.summonedForTrialLinks}
                                                        className="mb-5"
                                                    />
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.publicAndInsightsHeading.value}
                                                        listProp={props.settings.publicAndInsightsLinks}
                                                        className="mb-5"
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}

                                    {(props.settings?.themeLinks?.value?.length > 0 ||
                                        props.settings?.servicesLinks?.value?.length > 0 ||
                                        props.settings?.logInLinks?.value?.length > 0) && (
                                        <>
                                            <div className="grid-horizontal-mobile"></div>
                                            <Link
                                                className={`main-menu-item-mobile ${
                                                    subMenuTypeSelected === 'servicesAndForms' ? 'selected' : ''
                                                }`}
                                                href="#services"
                                                onClick={() => anchorClickHandler('servicesAndForms')}
                                            >
                                                <Typography typographyType="bodySans02" as="div" id="servicesAndForms">
                                                    {props.settings?.servicesAndFormsHeading?.value}
                                                </Typography>
                                                <Icon
                                                    color="rgba(53, 71, 84, 1)"
                                                    icon={
                                                        subMenuType.includes('servicesAndForms')
                                                            ? ChevronUpIcon
                                                            : ChevronDownIcon
                                                    }
                                                    iconSize="small"
                                                />
                                            </Link>
                                            {subMenuType.includes('servicesAndForms') && (
                                                <div className={`submenus-mobile pt-5`}>
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.themeHeading.value}
                                                        listProp={props.settings.themeLinks}
                                                        className="mb-5"
                                                    />
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.servicesHeading.value}
                                                        listProp={props.settings.servicesLinks}
                                                        className="mb-5"
                                                    />
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.logInHeading.value}
                                                        listProp={props.settings.logInLinks}
                                                        className="mb-5"
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}

                                    {(props.settings?.courtsInNorwayLinks?.value?.length > 0 ||
                                        props.settings?.judgesLinks?.value?.length > 0 ||
                                        props.settings?.aboutUsLinks?.value?.length > 0) && (
                                        <>
                                            <div className="grid-horizontal-mobile"></div>
                                            <Link
                                                className={`main-menu-item-mobile ${
                                                    subMenuTypeSelected === 'aboutUs' ? 'selected' : ''
                                                }`}
                                                href="#aboutus"
                                                onClick={() => anchorClickHandler('aboutUs')}
                                            >
                                                <Typography typographyType="bodySans02" as="div" id="aboutUs">
                                                    {props.settings.aboutHeading.value}
                                                </Typography>
                                                <Icon
                                                    color="rgba(53, 71, 84, 1)"
                                                    icon={
                                                        subMenuType.includes('aboutUs')
                                                            ? ChevronUpIcon
                                                            : ChevronDownIcon
                                                    }
                                                    iconSize="small"
                                                />
                                            </Link>
                                            {subMenuType.includes('aboutUs') && (
                                                <div className={`submenus-mobile pt-5`}>
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.courtsInNorwayHeading.value}
                                                        listProp={props.settings.courtsInNorwayLinks}
                                                        className="mb-5"
                                                    />
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.judgesHeading.value}
                                                        listProp={props.settings.judgesLinks}
                                                        className="mb-5"
                                                    />
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.aboutUsHeading.value}
                                                        listProp={props.settings.aboutUsLinks}
                                                        className="mb-5"
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}

                                    <div className="grid-horizontal-mobile mobile-bottom"></div>
                                    <div className="mobile-bottom"></div>
                                </>
                            )}
                        </nav>
                    )}
                </div>
            )}
        </div>
    );
};

export const ConnectedLanguageSelector = connect((state: State.CmsAppState) => state.OptiContentCloud || {})(
    DefaultHeaderMobile
);

export const HeaderMobile = (props: MobileProps) => {
    const ctx = useEpiserver();
    if (ctx.isServerSideRendering()) return <HeaderMobile {...props} />;
    return <ConnectedLanguageSelector {...props} />;
};

export default HeaderMobile;
