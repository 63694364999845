import {
    FacebookIcon,
    FlickrIcon,
    FooterListHeader,
    FooterSocialsGroup,
    FooterSocialsList,
    Icon,
    InstagramIcon,
    Link,
    LinkedInIcon,
    XIcon,
} from '@norges-domstoler/dds-components';
import SocialMediaBlockData from '../../../Models/SocialMediaBlockData';

const areAllLinksNull = (socialMediaBlock: SocialMediaBlockData) => {
    return !(
        socialMediaBlock?.facebook?.value ||
        socialMediaBlock?.twitter?.value ||
        socialMediaBlock?.instagram?.value ||
        socialMediaBlock?.linkedIn?.value ||
        socialMediaBlock?.flickr?.value
    );
};

export const FooterSocialMediaSection = ({ socialMediaBlock }: { socialMediaBlock: SocialMediaBlockData }) => {
    if (areAllLinksNull(socialMediaBlock)) {
        return null;
    }

    return (
        <FooterSocialsGroup>
            <FooterListHeader>{socialMediaBlock?.header?.value}</FooterListHeader>
            <FooterSocialsList>
                {socialMediaBlock?.facebook?.value && (
                    <li>
                        <Link href={socialMediaBlock.facebook.value} htmlProps={{ title: 'Facebook' }}>
                            <Icon icon={FacebookIcon} />
                        </Link>
                    </li>
                )}
                {socialMediaBlock?.twitter?.value && (
                    <li>
                        <Link href={socialMediaBlock.twitter.value} htmlProps={{ title: 'X' }}>
                            <Icon icon={XIcon} />
                        </Link>
                    </li>
                )}
                {socialMediaBlock?.instagram?.value && (
                    <li>
                        <Link href={socialMediaBlock.instagram.value} htmlProps={{ title: 'Instagram' }}>
                            <Icon icon={InstagramIcon} />
                        </Link>
                    </li>
                )}
                {socialMediaBlock?.linkedIn?.value && (
                    <li>
                        <Link href={socialMediaBlock.linkedIn.value} htmlProps={{ title: 'LinkedIn' }}>
                            <Icon icon={LinkedInIcon} />
                        </Link>
                    </li>
                )}
                {socialMediaBlock?.flickr?.value && (
                    <li>
                        <Link href={socialMediaBlock.flickr.value} htmlProps={{ title: 'Flickr' }}>
                            <Icon icon={FlickrIcon} />
                        </Link>
                    </li>
                )}
            </FooterSocialsList>
        </FooterSocialsGroup>
    );
};

export default FooterSocialMediaSection;
