import { useEffect, useRef, useState } from 'react';
import LayoutSettings from '../../Models/Content/LayoutSettingsData';
import { SiteType, getSiteTypeName } from '../../Models/Enums/SiteType';
import { useEpiserver } from '@episerver/spa-core';
import { MoseyLayoutProps } from './MoseyLayoutProps';
import { HeaderTilsynsutvalget } from './TopMenu/Header/TU/HeaderTilsynsutvalget';
import { Header } from './TopMenu/Header/Header';
import { Helmet } from 'react-helmet-async';
import store from '../../store';
import Footer from './Footer/Footer';
import './Layout.scss';

export const DefaultLayout = (
    props: MoseyLayoutProps & {
        layoutSettings?: LayoutSettings;
        language?: string;
        children?: React.ReactNode;
        pathname?: string;
    }
) => {
    const topRef = useRef<HTMLSpanElement>(null);
    const mainRef = useRef<HTMLElement>(null);
    const context = useEpiserver();
    const [menuOpen, setMenuOpen] = useState(false);

    if (!context.isServerSideRendering()) {
        document.body.classList.remove('supremeCourt');
        document.body.classList.remove('supervisoryCommittee');
    }
    useEffect(() => {
        topRef.current?.focus();
    }, [props.pathname]);

    useEffect(() => {
        if (!context.isServerSideRendering()) {
            document.body.addEventListener('mainEvent', handleMainEvent);
        }

        store.dispatch({ type: 'isMenuOpen', payload: false });
    }, []);

    store.subscribe(() => {
        setMenuOpen(store.getState().isMenuOpen);
    });

    const handleMainEvent = () => {
        mainRef.current?.focus();
    };

    const siteType = props.layoutSettings?.siteType?.value;

    return (
        <div
            className={`mosey-layout ${getSiteTypeName(siteType)}`}
            style={context.isServerSideRendering() ? { visibility: 'hidden' } : {}}
        >
            <span className={menuOpen ? 'visually-hidden' : ''} ref={topRef} tabIndex={-1}></span>
            <Helmet htmlAttributes={{ lang: props.language }}>
                <meta property="og:type" content="website" />
                <meta property="og:locale" content={props.language ?? 'no'} />
            </Helmet>

            {!context.isServerSideRendering() && (
                <>
                    {siteType == SiteType.Tilsynsutvalget ? (
                        <HeaderTilsynsutvalget settings={props.layoutSettings} path={props.pathname} />
                    ) : (
                        <Header settings={props.layoutSettings} path={props.pathname} />
                    )}
                </>
            )}
            <main id="main" ref={mainRef} className={menuOpen ? 'visually-hidden' : ''} tabIndex={-1}>
                {props.children}
            </main>
            {!context.isServerSideRendering() && !menuOpen && <Footer settings={props.layoutSettings} />}
        </div>
    );
};

export default DefaultLayout;
