import { ContentDelivery } from '@episerver/spa-core';
import { LinksListBlock } from './LinkListBlock';
import { HeaderProps } from '../Header/Header';
import Link from '../../../../Components/Shared/Link/link';
import { Button } from '@norges-domstoler/dds-components';

type MenuItemLinkModel = {
    links: ContentDelivery.LinkListProperty;
    linksHeading: string;
};

type MenuItemProps = HeaderProps & {
    childPageVisited: boolean;
    menuItemHeading: string;
    showDropDown: boolean;
    clickOnMenuItem?: () => void;
    menuItemRef?: React.MutableRefObject<any>;
    listProp?: MenuItemLinkModel[];
    clickedOnLink?: any;
    selected?: boolean;
    href?: string;
    id?: string;
};

export const MenuItem = (props: MenuItemProps) => {
    const links = props.listProp?.map((menuItemModel, index) => {
        if (menuItemModel?.linksHeading === '' || menuItemModel?.links?.value?.length === 0) {
            return null;
        }
        return (
            <LinksListBlock
                key={`${index}-${menuItemModel.linksHeading}`}
                isMobile={false}
                isMenuOpen={(isMenuOpen: boolean) => props.clickedOnLink(isMenuOpen)}
                {...props}
                header={menuItemModel.linksHeading}
                listProp={menuItemModel.links}
                className="col-4 sub-menu-column"
            />
        );
    });

    if (props.href === '' && (props.menuItemHeading === '' || links.length < 1)) {
        return null;
    }

    const isAnchorEl = props.href !== '';

    return (
        <li className="me-1" role="none">
            {isAnchorEl ? (
                <Link
                    aria-haspopup="true"
                    aria-expanded="false"
                    href={props.href}
                    id={props.id !== '' ? props.id : null}
                    className={`header-menu-item ${props.selected ? 'selected' : ''} ${
                        props.childPageVisited ? 'child-page-visited' : ''
                    }`}
                >
                    <span>{props.menuItemHeading}</span>
                </Link>
            ) : (
                <Button
                    purpose="tertiary"
                    htmlProps={{ role: 'menuitem', tabIndex: -1 }}
                    aria-haspopup="true"
                    aria-expanded="false"
                    id={props.id !== '' ? props.id : null}
                    className={`header-menu-item ${props.selected ? 'selected' : ''} ${
                        props.childPageVisited ? ' child-page-visited' : ''
                    }`}
                    onClick={() => props.clickOnMenuItem()}
                >
                    <span>{props.menuItemHeading}</span>
                </Button>
            )}
            {!isAnchorEl && (
                <div
                    ref={props.menuItemRef}
                    className={`nav-menu-group py-5 d-none ${
                        props.showDropDown ? 'd-lg-flex sub-menu-min-height' : ''
                    }`}
                >
                    {links}
                </div>
            )}
        </li>
    );
};

MenuItem.defaultProps = {
    menuItemHeading: '',
    menuItemRef: null,
    selected: false,
    listProp: null,
    href: '',
    id: '',
};

export default MenuItem;
